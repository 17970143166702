import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../core/constants';
import MainLayout from '../../../../layouts/Main';
import { useAppDispatch } from '../../../../store';
import { searchPracticeThunk } from '../../../../store/practices';
import FAQStrip from './components/FAQStrip';
import GeneticDataStrip from './components/GeneticDataStrip';
import HeroUK from './components/HeroUK';
import InvolvementStrip from './components/InvolvementStrip';
import TakePartStrip from './components/TakePartStrip';
import PostEnrolmentStrip from './components/PostEnrolmentStrip';
import PrivacyStrip from './components/PrivacyStrip';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DMUK_DEFAULT_LANG } from '../../../../core/constants';

function UnitedKingdomMainScene() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleSearch = (keyword: string) => {
    dispatch(searchPracticeThunk(keyword));
    navigate(ROUTES.unitedKingdomResults);
  };

  useEffect(() => {
    localStorage.setItem('lang', DMUK_DEFAULT_LANG);
    i18n.changeLanguage(DMUK_DEFAULT_LANG);
  }, [i18n]);

  return (
    <MainLayout>
      <HeroUK handleSearch={handleSearch} />
      <TakePartStrip />
      <InvolvementStrip />
      <PostEnrolmentStrip />
      <GeneticDataStrip />
      <FAQStrip />
      <PrivacyStrip />
    </MainLayout>
  );
}

export default UnitedKingdomMainScene;
